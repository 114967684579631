import { useParams } from "react-router";
import NotFound from "./NotFound";
import { Schlossgarten } from "./Schlossgarten";
import { OhneSchlossgarten } from "./OhneSchlossgarten";
import { getAnjuGuestsFromIdentifier } from "./anjuGuests";
import { getSchlossgartenGuestsFromIdentifier } from "./schlossgartenGuests";

function App() {
  const params = useParams();
  const identifier = params.identifier;
  const schlossgartenGuests = getSchlossgartenGuestsFromIdentifier(
    identifier ?? ""
  );
  const anjuGuests = getAnjuGuestsFromIdentifier(identifier ?? "");

  return (
    <>
      {schlossgartenGuests !== undefined && <Schlossgarten />}
      {anjuGuests !== undefined && <OhneSchlossgarten />}
      {(identifier === undefined ||
        (anjuGuests === undefined && schlossgartenGuests === undefined)) && (
        <NotFound />
      )}
    </>
  );
}

export default App;
