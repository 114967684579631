export type GuestAndGender = {
  name: string;
  gender: "male" | "female";
}[];

export const invitedGuests: Record<
  string,
  { name: string; gender: "male" | "female" }[]
> = {
  gn82CJh24: [
    { name: "Simon", gender: "male" },
    { name: "Lea", gender: "female" },
  ],
  gn77YOh24: [
    { name: "Katja", gender: "female" },
    { name: "Lucy", gender: "female" },
    { name: "Liza", gender: "female" },
    { name: "Manoel", gender: "male" },
    { name: "Santos", gender: "male" },
  ],
  gnG761h24: [
    { name: "John", gender: "male" },
    { name: "Chloé", gender: "female" },
  ],
  gnECS7h24: [
    { name: "Fab", gender: "male" },
    { name: "Nadja", gender: "female" },
  ],
  gnW3ZKh24: [{ name: "Ele", gender: "female" }],
  gnIF7Lh24: [
    { name: "Duc", gender: "male" },
    { name: "Trang", gender: "female" },
  ],
  gnF8IPh24: [
    { name: "Lea", gender: "female" },
    { name: "Martin", gender: "male" },
    { name: "Matilda", gender: "female" },
  ],
  gnDD4Vh24: [
    { name: "Albert", gender: "male" },
    { name: "Eli", gender: "female" },
    { name: "Luis", gender: "male" },
  ],
  gnET2Ch24: [{ name: "Stefan", gender: "male" }],
  gnZB14h24: [
    { name: "Daniela", gender: "female" },
    { name: "Björn", gender: "male" },
  ],
  gnMEC8h24: [
    { name: "Richard", gender: "male" },
    { name: "Zoé", gender: "female" },
  ],
  gnKIYDh24: [{ name: "Kati", gender: "female" }],
  gn296Qh24: [
    { name: "Liskl", gender: "female" },
    { name: "Jorin", gender: "female" },
  ],
  gnIZA5h24: [{ name: "Phi-Long", gender: "male" }],
  gnUHJXh24: [{ name: "Lukas", gender: "male" }],
  test: [
    { name: "ich", gender: "female" },
    { name: "bin", gender: "female" },
    { name: "ein", gender: "male" },
    { name: "test", gender: "male" },
  ],
};

export function getAnjuGuestsFromIdentifier(
  identifier: string
): GuestAndGender | undefined {
  if (invitedGuests.hasOwnProperty(identifier)) {
    return invitedGuests[identifier];
  }
  return undefined;
}
