// AppRouter.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App"; // Ihre Hauptkomponente
import NotFound from "./NotFound"; // Eine Komponente für den Fall, dass der Pfad nicht übereinstimmt

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/:identifier" Component={App} />
        <Route path="*" Component={NotFound} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
