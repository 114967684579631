/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const NinaGeorg = () => {
  return (
    <>
      <img
        src={require("./assets/IMG_0542-min.jpg")}
        alt="Blumen"
        css={styles.image}
      />
      <div css={styles.textContainer}>
        <div css={styles.text}>Nina </div>
        <div css={styles.text}> & </div>
        <div css={styles.text}> Georg</div>
      </div>
    </>
  );
};

export default NinaGeorg;

const styles = {
  image: css`
    width: 100%; /* Streckt das Bild über die volle Breite des übergeordneten Containers */
    height: auto; /* Passt die Höhe proportional an die Breite an */
  `,
  textContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 25px;
  `,
  text: css`
    font-size: 24px;
    font-family: "Great Vibes";
    /* font-weight: 600; */
  `,
};
