/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

export function OhneSchlossgarten() {
  return (
    <div css={styles.wrapper}>
      <div css={styles.flowerContainer}>
        <img
          src={require("./assets/OSG1.jpeg")}
          alt="Schlossgarten"
          css={styles.flowers}
        />
      </div>
      <div css={styles.flowerContainer}>
        <img
          src={require("./assets/OSG2.jpeg")}
          alt="Schlossgarten"
          css={styles.flowers}
        />
      </div>
    </div>
  );
}

const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
  `,
  flowerContainer: css`
    /* flex: 1; */
    margin-bottom: -10px;
  `,
  flowers: css`
    width: 100%; /* Streckt das Bild über die volle Breite des übergeordneten Containers */
    height: auto; /* Passt die Höhe proportional an die Breite an */
  `,
};
