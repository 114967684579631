export type GuestAndGender = {
  name: string;
  gender: "male" | "female";
}[];

export const invitedGuests: Record<
  string,
  { name: string; gender: "male" | "female" }[]
> = {
  gnC91Fh24: [
    { name: "Tine", gender: "female" },
    { name: "Luna", gender: "female" },
    { name: "Maggy", gender: "female" },
  ],
  gn7NV5h24: [
    { name: "Felix", gender: "male" },
    { name: "Zhanna", gender: "female" },
  ],
  gnOSPXh24: [
    { name: "Felix", gender: "male" },
    { name: "Jessi", gender: "female" },
  ],
  gn0APQh24: [
    { name: "Maria", gender: "female" },
    { name: "Klemens", gender: "male" },
  ],
  gn1ES5h24: [{ name: "Isabella", gender: "female" }],
  gn1UGMh24: [{ name: "Vivien", gender: "female" }],
  gnMLPEh24: [{ name: "Laurena", gender: "female" }],
  gnAF09h24: [{ name: "Caddy", gender: "female" }],
  gn8JMOh24: [{ name: "Sissy", gender: "female" }],
  gnHKT1h24: [
    { name: "Peter", gender: "male" },
    { name: "Hermi", gender: "female" },
  ],
  gnFOG6h24: [{ name: "Daniel", gender: "male" }],
  gnFHG6h24: [
    { name: "Luisa", gender: "female" },
    { name: "Maxi", gender: "male" },
  ],
  gnFUG6h24: [
    { name: "Barbara", gender: "female" },
    { name: "Laci", gender: "male" },
  ],
  gnP44Nh24: [
    { name: "Heidi", gender: "female" },
    { name: "Robert", gender: "male" },
    { name: "Tanja", gender: "female" },
    { name: "Niclas", gender: "male" },
  ],
  test: [
    { name: "ich", gender: "female" },
    { name: "bin", gender: "female" },
    { name: "ein", gender: "male" },
    { name: "test", gender: "male" },
  ],
};

export function getSchlossgartenGuestsFromIdentifier(
  identifier: string
): GuestAndGender | undefined {
  if (invitedGuests.hasOwnProperty(identifier)) {
    return invitedGuests[identifier];
  }
  return undefined;
}
